import React from 'react';
import tiktok from 'assets/img/tiktok.png'
import ws from 'assets/img/ws.png'
import gmail from 'assets/img/gmail2.png'
import ig from 'assets/img/ig.png'

const socialLinks = [
  {
    href: "https://wa.link/oljswz",
    image: ws,
    alt: "WhatsApp",
    description: "Barquisimeto"
  },
  {
    href: "https://www.instagram.com/dentalcenter.ve/profilecard/?igsh=ZDJya21meTRoenB3",
    image: ig,
    
    alt: "Instagram",
    description: "Instagram"
  },
  {
    href: "mailto:odontologia.anticanceroso@gmail.com",
    image: gmail,
    alt: "Gmail",
    description: "Gmail"
  },
  {
    href: "https://www.tiktok.com/@dentalcenter.ve?_t=8qTBylFag3q&_r=1",
    image: tiktok,
    alt: "TikTok",
    description: "TikTok"
  }, 
  {
    href: "https://wa.link/xyvzpr",
    image: ws,
    alt: "WhatsApp",
    description: "Valencia"
  },
];

function Redes() {
  return (
    <div style={styles.container}>
      <h1 className='text-4xl font-bold tracking-tight text-gray-900 mb-20'>Síguenos en nuestras Redes Sociales</h1>
      <div style={styles.iconsContainer}>
        {socialLinks.map((link, index) => (
          <div className='justify-center' key={index} style={styles.iconBox}>
            <a href={link.href} target="_blank" rel="noopener noreferrer">
              <img src={link.image} alt={link.alt} style={styles.icon} />
            </a>
            <p className='text-sm font-medium text-gray-900' style={styles.description}>{link.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
}

const styles = {
  container: {
    textAlign: 'center',
    padding: '20px',
    marginBottom: '130px',
    marginTop: '40px'

  },
  iconsContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    
    justifyContent: 'center'
  },
  iconBox: {
    margin: '80px',
    
    textAlign: 'center'
  },
  icon: {
    width: '70px',
    height: '70px',
    marginLeft: '0px',
    position: 'center',
  },
  description: {
    marginTop: '15px'
  }
};

export default Redes;