import logo_dental from 'assets/img/logodental.png'
import valencia_direc from 'assets/img/valenciadirec.jpg'
import vargas_direc from 'assets/img/vargasdirec.jpg'
import riolama_direc from 'assets/img/riolamadirec.jpg'
import horario from 'assets/img/horario.png'

const posts = [
    {
      title: 'Sede Valencia',
      href: '#',
      category: { name: 'Valencia', href: '#' },
      description:
        'CALLE CANTAURA 94 ENTRE AV. PRINCIPAL LAS FERIAS Y URDANETA. SANTA ROSA.',
      date: 'Mar 16, 2020',
      datetime: '2020-03-16',
      image: valencia_direc,
      author: {
        name: '8:00am - 4:00pm',
        href: '#',
        imageUrl:
          horario,
      },
    },
    {
      title: 'Sede Este - Rio Lama',
      href: '#',
      category: { name: 'Barquisimeto', href: '#' },
      description:
        'AV. LARA CON AV. TEREPAIMA CENTRO COMERCIAL RIO LAMA, QUINTA ETAPA TORRE DE OFICINAS. PISO 4 CONSULTORIO 4-6.',
      date: 'Mar 10, 2020',
      datetime: '2020-03-10',
      image: riolama_direc,
      author: {
        name: '8:00am - 4:00pm',
        href: '#',
        imageUrl:
          horario,
      },
    },
    {
      title: 'Sede Sociedad Anticancerosa',
      href: '#',
      category: { name: 'Barquisimeto', href: '#' },
      description:
        'AV. VARGAS ENTRE CARRERAS 32 Y 33. EDIFICIO DE LA SOCIEDAD ANTICANCEROSA DEL ESTADO LARA. PB..',
      date: 'Feb 12, 2020',
      datetime: '2020-02-12',
      image: vargas_direc,
      author: {
        name: '8:00am - 4:00pm',
        href: '#',
        imageUrl:
          horario,
      },
    },
    
  ]
  
  export default function Example() {
    return (
      <div className="relative bg-gray-50 px-4 pt-16 pb-20 sm:px-6 lg:px-8 lg:pt-24 lg:pb-28">
        <div className="absolute inset-0">
          <div className="h-1/3 bg-white sm:h-2/3" />
        </div>
        <div className="relative mx-auto max-w-7xl">
          <div className="text-center">
            <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Nuestras Sedes</h2>
            <p className="mx-auto mt-3 max-w-2xl text-xl text-gray-500 sm:mt-4">
              Te esperamos en tu sede de preferencia o mas cercana a ti.
            </p>
          </div>
          <div className="mx-auto mt-12 grid max-w-lg gap-5 lg:max-w-none lg:grid-cols-3">
            {posts.map((post) => (
              <div key={post.title} className="flex flex-col overflow-hidden rounded-lg shadow-lg">
                <div className="flex-shrink-0">
                  <img className="h-148 w-full object-cover" src={post.image} alt="" />
                </div>
                <div className="flex flex-1 flex-col justify-between bg-white p-6">
                  <div className="flex-1">
                    <p className="text-sm font-medium text-indigo-600">
                      <a href={post.category.href} className="hover:underline">
                        {post.category.name}
                      </a>
                    </p>
                    <a href={post.href} className="mt-2 block">
                      <p className="text-xl font-semibold text-gray-900">{post.title}</p>
                      <p className="mt-3 text-base text-gray-500">{post.description}</p>
                    </a>
                  </div>
                  <div className="mt-6 flex items-center">
                    <div className="flex-shrink-0">
                      <a href={post.author.href}>
                        <span className="sr-only">{post.author.name}</span>
                        <img className="h-10 w-10 rounded-full" src={post.author.imageUrl} alt="" />
                      </a>
                    </div>
                    <div className="ml-3">
                      <p className="text-sm font-medium text-gray-900">
                        <a href={post.author.href} className="hover:underline">
                          {post.author.name}
                        </a>
                      </p>
                      <div className="flex space-x-1 text-sm text-gray-500">
                        <time dateTime={post.datetime}>{post.date}</time>
                        <span aria-hidden="true">&middot;</span>
                        <span>{post.readingTime} read</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    )
  }