import treinta from 'assets/img/30years.png'
import garantia from 'assets/img/garantia.png'
import cliente from 'assets/img/cliente.png'

const incentives = [
    {
      name: '30 años de experiencia',
      imageSrc: treinta,
      description: "Contamos con +30 años de experiencia.",
      
    }, 
    {
      name: 'Atención al Cliente',
      imageSrc: cliente,
      description: "Un excelente servicio de atención al cliente.",
      href: '/Barquisimeto',
    },
    {
      name: 'Garantía',
      imageSrc: garantia,
      description:
        "Garantía en los servicios.",
    },
  ]
  
  export default function Incentives() {
    return (
      <div className="bg-white">
        <div className="mx-auto max-w-7xl py-24 sm:px-2 sm:py-32 lg:px-4">
          <div className="mx-auto max-w-2xl px-4 lg:max-w-none">
            <div className="max-w-3xl">
              <h2 className="text-4xl font-bold tracking-tight text-gray-900">
                Atención Profesional de Odontología
              </h2>
              <p className="mt-4 text-gray-500">
              Bienvenidos a Dental Center, donde la salud y la sonrisa de nuestros pacientes son nuestra máxima prioridad. Con más de 30 años de experiencia en el campo de la odontología, nuestro equipo de profesionales altamente capacitados se dedica a brindar atención dental de excelencia en un ambiente cálido y acogedor. Nos enorgullece ofrecer una amplia gama de servicios, desde chequeos rutinarios hasta tratamientos especializados, siempre con un enfoque personalizado que se adapta a las necesidades de cada paciente. Tu sonrisa es nuestra pasión, y estamos aquí para ayudarte a mantenerla saludable y radiante. ¡Explora nuestros servicios y descubre cómo podemos cuidar de ti!
              </p>
            </div>
            <div className="mt-16 grid grid-cols-1 gap-y-10 gap-x-8 lg:grid-cols-3">
              {incentives.map((incentive) => (
                <div key={incentive.name} className="sm:flex lg:block">
                  <div className="sm:flex-shrink-0">
                    <img className="h-16 w-16" src={incentive.imageSrc} alt="" />
                  </div>
                  <div className="mt-4 sm:mt-0 sm:ml-6 lg:mt-6 lg:ml-0">
                    <h3 className="text-sm font-medium text-gray-900">{incentive.name}</h3>
                    <p className="mt-2 text-sm text-gray-500">{incentive.description}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    )
  }