import React from 'react';
import './WhatsAppButton.css'; // Asegúrate de crear este archivo para los estilos

const WhatsAppButton = () => {
    return (
        <a
            href="https://wa.link/oljswz" // Cambia el número al que deseas enlazar
            target="_blank"
            rel="noreferrer"
            className="whatsapp-button"
        >
            <img
                src="https://upload.wikimedia.org/wikipedia/commons/6/6b/WhatsApp.svg" // Aquí puedes usar el enlace de tu logo
                alt="WhatsApp"
            />
        </a>
    );
};

export default WhatsAppButton;